import React from 'react'
import { categories } from "../api/api.js";
import {Link} from 'react-router-dom'

const AllCategories = () => {
	return (
		<>
			
      {/* C-CATEGORIES */}
      <section className="top_categories">
        <div className="container">
        	<h2 className="heading">all categories</h2>
          <div className="t_cat">
          	{
          		categories.map(function(category, key) {
                {/* to={category.url} ka dynamic URL*/}
          			return(
			            <Link key={key} to="/products" className="t_cat_item">
			            	<h2 className="cat_title">{category.title}</h2>
			            </Link>
          			);
          		})
          	}
          </div>
        </div>
      </section>
		</>
	)
}

export default AllCategories