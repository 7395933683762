import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { navigation } from "../api/api.js";
import * as Icons from "@tabler/icons-react";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {/* C-HEADER */}
      <header>
        <div className="container">
          <div className="header_main">
            <div className="header_contact">
          {/* C-LOGO */}
          <div className="logo_wrapper">
            <Link to="/" className="logo">
              <img src="/images/common/logo.png" alt="Logo" />
            </Link>
          </div>
        </div>
        <div className="header_top">
          <div className="header_top_main">
            <div className="header_contact_main">
              <div className="header_contact_flex">
                <p className="header_address">
                  <Icons.IconMapPins />
                  6604 Millwood Road, Bethesda, MD 20817
                </p>
                <p className="header_address">
                  <Icons.IconAddressBook />
                  +1-951-638-3508
                </p>
                <p className="header_address">
                  <Icons.IconMail />
                  info@supplynodes.com
                </p>
              </div>
            </div>
            <button
              className={`menu_btn ${isOpen ? "active" : ""}`}
              onClick={toggleMenu}
            >
              <Icons.IconMenu2 />
            </button>
            <nav>
              <ul className={`navigation  ${isOpen ? "active" : ""}`}>
                <button
                  className={`menu_btn close ${isOpen ? "active" : ""}`}
                  onClick={toggleMenu}
                >
                  <Icons.IconX />
                </button>
                {navigation.map(function (nav, key) {
                  return (
                    <li key={key} onClick={toggleMenu}>
                      <NavLink to={nav.url}>{nav.title}</NavLink>
                    </li>
                  );
                })}

              <a href="https://www.amazon.com/s?me=AP21M8TF4B9DS&ref=sf_seller_app_share_new" target="_blank" className="amazon_link">    
                <img src="/images/common/amazone.png" alt=""/>
                <div className="amazon_content">
                  <p>Shop now</p>
                </div>   
              </a>
              </ul>
            </nav>
          </div>
        </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
