import React from 'react';

const Button = ({ className, text, onClick }) => {
  return (
    <>
      <button className={`btn ${className ? className : ''}`} onClick={onClick}>
        <span>{text}</span>
      </button>
    </>
  );
};

export default Button;
