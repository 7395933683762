import React from 'react'
import { products } from "../api/api.js";
import * as Icons from "@tabler/icons-react";
import {Link} from 'react-router-dom'

const AllPdorducts = () => {
	const handleBuy = (title) => {
    const messageText = `*I Want to puchased:* ${title}`;
    const whatsappNumber = "+16465486689";
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      messageText
    )}`;

    window.open(whatsappUrl, "_blank");
  }
   return (
      <section>
			<div className="container">
			<h2 className="heading">all products</h2>
		<div className="all_product_grid">
			{
				products.map(function(product, key) {
					return(
						<div className="product-card" key={key}>
		                    <figure  className="product-image" >
		                    	{/*DYNAMIC LINK product.category.link*/}
		                      <Link to="/categories" className="product_tag">{product.category.name}</Link>
		                      <img src={product.image} alt={product.title}/>
		                    </figure>
		                    <h3 className="product-title">{product.title}</h3>
		                    <p className="product-description">{product.description}</p>
		                    <div className="product_card_footer">
		                      <p className="product-price">${product.price}</p>
		                      <button className="product-button" onClick={(e) => handleBuy(product.title)}>
		                        <Icons.IconShoppingBag/>
		                        <span>buy now</span>
		                      </button>
		                    </div>
		                  </div>
					);
				})
			}    
		</div>
			</div>
		</section>
   )
}

export default AllPdorducts