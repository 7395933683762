import React from 'react'
import Header from './Header.js'
import Footer from './Footer.js'
import Home from '../pages/Home.js'
import {Routes,Route} from 'react-router-dom'
import ContactUs from '../pages/ContactUs.js'
import AllPdorducts from '../pages/AllPdorducts.js'
import AllCategories from '../pages/AllCategories.js'
import About from '../pages/About.js'
const Main = () => {
	return (
		<>
			<Header/>
			<Routes>
				<Route path="/" element={<Home/>}/>
				<Route path="/contact" element={<ContactUs/>}/>
				<Route path="/categories" element={<AllCategories/>}/>
				<Route path="/products" element={<AllPdorducts/>}/>
				<Route path="/about" element={<About/>}/>
			</Routes>
			
			<Footer/>
		</>
	)
}

export default Main