import React from "react";
import { Link } from "react-router-dom";
import { footer } from "../api/api";
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="container">
        <div className="footer_main">
          <Link to="/" className="logo">
            <img src="/images/common/logo.png" alt="Logo" />
          </Link>
          {footer.map((footer) => (
            <div key={footer.title}>
              <h4>{footer.title}</h4>
              <ul>
                {footer.links.map((link) => (
                  <li key={link.label}>
                    {link.url.startsWith("/") ? (
                      <Link to={link.url}>{link.label}</Link>
                    ) : (
                      <a href={link.url}>{link.label}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}

        </div>
      </div>
    </footer>
  );
};

export default Footer;
