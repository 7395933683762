import React from "react";

const About = () => {
  return (
    <>
      <section className="about">
        <div className="container">
          <div className="about_main">
            <h2 className="heading">Welcome to Supply Nodes!</h2>
            <p>
                Supply Nodes is your premier destination for a delightful Ecommerce shopping experience! We take immense
                pride in curating a vast collection of products featuring attractive, simpler, and elegant designs across
                various categories, including Pet Supplies, Home and Kitchen, and Health and Beauty. Our commitment goes
                beyond merely satisfying clients; we strive to secure the unwavering satisfaction of every single customer
                who chooses to embark on this journey with us.
            </p>
            <p>
                At Supply Nodes, we believe that shopping should be a pleasure, not a chore. Therefore, we've created a
                user-friendly and intuitive website that allows you to explore and discover a wide array of handpicked
                products that not only meet your needs but exceed your expectations. Our dedication to excellence and
                quality craftsmanship is evident in each item we offer, ensuring that you receive products that enrich
                your lifestyle and elevate your everyday experiences.
            </p>
            <p>
                What sets us apart from other Ecommerce platforms is our relentless focus on customer satisfaction. We
                understand that our success hinges on your happiness and contentment, which is why we put your needs at
                the forefront of everything we do. Our team of experienced and passionate individuals work tirelessly to
                source the finest products, ensuring they align with your preferences, style, and values.
            </p>
            <p>
                We take great care to establish lasting relationships with our customers, fostering an environment of
                trust, reliability, and transparency. Whether you're a pet lover seeking the perfect supplies for your
                furry companion, a homemaker looking to elevate your living spaces, or someone seeking beauty and wellness
                products to nourish your mind and body, Supply Nodes is your one-stop-shop.
            </p>
            <p>
                Moreover, we are committed to continuously improving and expanding our product offerings, seeking out the
                latest trends and timeless classics to cater to your evolving needs. Our dedication to staying at the
                cutting edge of the industry ensures that you have access to the best and most innovative products in the
                market.
            </p>
            <p>
                As a responsible and ethical Ecommerce platform, we also strive to contribute positively to the communities
                we serve and the environment we inhabit. We promote sustainability by working with suppliers who share our
                values and support eco-friendly initiatives whenever possible.
            </p>
            <p>
                Join us on this incredible shopping journey and discover a world of possibilities. Experience the
                convenience of browsing through carefully curated collections, the joy of finding unique and stunning
                products, and the assurance that your satisfaction is our top priority.
            </p>
            <p>
                Thank you for choosing Supply Nodes. We look forward to bringing a smile to your face with every purchase
                and making your shopping experience truly memorable. Feel free to reach out to us with any questions,
                feedback, or special requests – we are here to serve you, the valued customer, and ensure your absolute
                delight!
            </p>
          </div>
          <div>
            <h2 className="heading">Privacy Policy</h2>
            <div>
              <h4 className="sub_heading">Collection of Information:</h4>
              <p>
                We may collect personal information such as your name, email
                address, phone number, and other relevant details when you
                interact with our website, subscribe to our newsletters, make
                purchases, or engage with our services. We may also collect
                non-personal information, such as cookies, IP addresses, and
                browsing patterns, to enhance your website experience.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Use of Information:</h4>
              <p>
                We use the collected information to provide you with a
                personalized experience, fulfill your orders, respond to
                inquiries, improve our products and services, and communicate
                with you about updates, promotions, and relevant information. We
                may also use your information for internal research, analysis,
                and marketing purposes.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Disclosure of Information:</h4>
              <p>
                We do not sell, trade, or rent your personal information to
                third parties without your explicit consent, except as required
                by law or to fulfill our contractual obligations. We may share
                your information with trusted partners and service providers who
                assist us in delivering our products and services. These
                partners are obligated to maintain the confidentiality and
                security of your information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Security Measures:</h4>
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, loss, misuse, or alteration. However,
                please note that no method of transmission over the internet or
                electronic storage is completely secure, and we cannot guarantee
                the absolute security of your information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Third-Party Websites:</h4>
              <p>
                Our website may contain links to third-party websites or
                services. We are not responsible for the privacy practices or
                the content of such websites. We encourage you to review the
                privacy policies of these third parties before providing any
                personal information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Cookies:</h4>
              <p>
                We may use cookies and similar technologies to enhance your
                browsing experience, analyze website traffic, and personalize
                content. You have the option to disable cookies through your
                browser settings, but please note that this may affect the
                functionality and usability of our website.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Children's Privacy:</h4>
              <p>
                Our website is not intended for children under the age of 13. We
                do not knowingly collect personal information from children. If
                you believe that we have inadvertently collected information
                from a child, please contact us, and we will promptly take steps
                to delete the information.
              </p>
            </div>
            <div>
              <h4 className="sub_heading">Updates to the Privacy Policy:</h4>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We encourage you
                to review this policy periodically for any updates. Continued
                use of our website or services after any changes signifies your
                acceptance of the updated Privacy Policy.
              </p>
            </div>
            <div>
              <p>
                If you have any questions, concerns, or requests regarding our
                Privacy Policy or the handling of your personal information,
                please contact us using the information provided below.
              </p>
              <p>
                Contact Information:
                <br />
                Supply Nodes
                <br />
                <b>Address</b>:  6604 Millwood Road, Bethesda, MD 20817
                <br />
                <b>Email</b> : info@supplynodes.com
                <br />
                <b>Phone</b> : +1-951-638-3508
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
