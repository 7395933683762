// DEALS BANNER
export const deals = [{
    id: 1,
    image: '/images/deals/deal_1.webp',
    title: "Huge Savings on Top Shoe Brands! Shop Now and Step Up Your Style!",
    description: "Discover incredible discounts on top shoe brands. From sneakers to heels, find your perfect style and save big. Shop now!",
    url: "/deal1" // Replace with the desired URL for the deal
  },
  {
    id: 2,
    image: '/images/deals/deal_2.webp',
    title: "Limited Time Offer: Exclusive Deals on the Latest Shoe Trends!",
    description: "Don't miss out on exclusive deals for a limited time! Explore the latest shoe trends and enjoy special discounts. Upgrade your footwear collection today!",
    url: "/deal2" // Replace with the desired URL for the deal
  },
  {
    id: 3,
    image: '/images/deals/deal_3.webp',
    title: "Unbeatable Prices on Athletic Shoes! Get Active with Style!",
    description: "Get ready to elevate your athletic performance with style! Shop our wide selection of athletic shoes at unbeatable prices. Find the perfect pair and hit your fitness goals in style!",
    url: "/deal3" // Replace with the desired URL for the deal
  }
];
// NAVIGATION
export const navigation = [{
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 4,
    title: "categories",
    url: "/categories",
  },
  {
    id: 5,
    title: "Contact",
    url: "/contact",
  },
];

// TOP CATEGORIES
export const categories = [{
    id: 1,
    title: "Beads",
    url: "/health-beauty"
  },
  {
    id: 2,
    title: "Cords & Stringing",
    url: "/cleaning-household"
  },
  {
    id: 3,
    title: "Finding",
    url: "/automotive"
  },
  {
    id: 4,
    title: "Pliers",
    url: "/baby-products"
  },
  {
    id: 5,
    title: "health care",
    url: "/health-care"
  },
  {
    id: 6,
    title: "Books",
    url: "/food-grocery"
  },
  {
    id: 7,
    title: "Electronics & Accessories",
    url: "/electronics-accessories"
  },
  {
    id: 8,
    title: "Office Products",
    url: "/office-products"
  }
];


//  FOOTER
export const footer = [
  {
    title: 'Company',
    links: [
      { label: 'About Us', url: '/about' },
      { label: 'Contact Us', url: '/contact' },
      { label: 'Careers', url: '/careers' }
    ]
  },
  {
    title: 'Products',
    links: [
      { label: 'Electronics', url: '/electronics' },
      { label: 'Home & Kitchen', url: '/home' },
      { label: 'Clothing', url: '/clothing' },
    ]
  },
  {
    title: 'Contact',
    links: [
      { label: '6604 Millwood Road, Bethesda, MD 20817', url: '' },
      { label: '+1-951-638-3508', url: '' },
      { label: 'info@supplynodes.com', url: '' },
    ]
  },
];
//  TOP PRODUCTS
export const products = [
{
    id: 1,
    image: 'https://m.media-amazon.com/images/I/81p1i8gPeAL._AC_SX679_.jpg',
    title: 'Soft Flex Beading Wire, White.014 Inch, 100 Feet | BDC-415.01',
    description: 'This white beading wire is the ultimate in nylon coated jewelry and beading wire. It is fast becoming the standard for professionals and hobbyists. Made up of 21 strands of stainless steel wire encased in nylon for the unbeatable combination of strength and flexibility. 0.014" in diameter (Fine); 100 feet.',
    price: 41.49,
    link: "https://www.amazon.com/Soft-Flex-Beading-White-BDC-415-01/dp/B0058EDXCK/ref=sr_1_11?keywords=soft+flex+beading+wire&qid=1690466451&sr=8-11",
    category: {
      name: 'Beads',
    }
  },
  {
    id: 2,
    image: 'https://m.media-amazon.com/images/I/81MIcJRGPrL._AC_SX679_.jpg',
    title: 'Soft Flex 0.019-Inch Beading Wire, 30-Feet, Grey',
    description: 'This wire has more body than string or cord and more flexibility than regular 3-strand beading wire. It has an unbelievably soft drape and a very high resistance to kinking and getting permanent "creases", unlike the less expensive wires. This is the wire used by professionals! This wire is a miniature cable made up of forty-nine strands of wire and coated with nylon for a smooth finish. Use this medium weight of Soft Flex beading wire for stringing necklaces with Czech Beads, small to medium metal beads. Absolutely perfect for Eyeglass Holders!. Finish with crimp beads. Quantity: Each lot consists of 1 spool of 30 feet of Soft Flex beading wire.',
    price: 13.98,
    link: "https://www.amazon.com/Soft-Flex-0-019-Inch-Beading-30-Feet/dp/B005DJV6GA/ref=sr_1_1?keywords=soft+flex+beading+wire&qid=1690466451&sr=8-1",
    category: {
      name: 'Beads',
    }
  },
  {
    id: 3,
    image: 'https://m.media-amazon.com/images/I/41ZP0K3Ur2L._SX218_BO1,204,203,200_QL40_FMwebp_.jpg',
    title: 'Be Your Own Doctor by Rachel Weaver',
    description: 'How to deal with colds, flus, and fever. - How to stop bleeding, heal wounds, and deal with emergencies. - Re-mineralizing teeth and healing abscesses. - Recipes to make your own tinctures, poultices, teas, and salves.',
    price: 29.99,
    category: {
      name: 'Books',
    }
  },
  {
    id: 4,
    image: 'https://m.media-amazon.com/images/I/61BqYKkeiXL._SX412_BO1,204,203,200_.jpg',
    title: 'David Shannon Set Pack of 4 Books ',
    description: "Brand new set of 4 books by David Shannon. Includes the following titles: It's Christmas, David!, No, David!, David Goes to School, and David Gets in Trouble.",
    price: 39.99,
    category: {
      name: 'Books',
    }
  },
  {
    id: 5,
    image: 'https://img.freepik.com/free-vector/night-cosmetic-series-face-skin-care_88138-9.jpg?w=1060&t=st=1690472271~exp=1690472871~hmac=86ce2d61e1e98fddaf45fe69f00d2079b5c2884a81f5695e3f92b23811eab7cb',
    title: 'Luxury Skincare Set',
    description: 'Pamper your skin with our luxurious skincare set for a radiant complexion.',
    price: 59.99,
    category: {
      name: 'Health care',
    }
  },
  {
    id: 6,
    image: 'https://img.freepik.com/free-photo/top-view-assortment-healthy-food_23-2148967549.jpg?w=996&t=st=1685984408~exp=1685985008~hmac=f6b6ed1bbdeda67903684954b5195ad62e11e0bc9b69a01f97e0c2437b67d76a',
    title: 'Healthy Snack Pack',
    description: 'Satisfy your cravings with our nutritious and delicious healthy snack pack.',
    price: 9.99,
    category: {
      name: 'Health care',
    }
  },
  {
    id: 7,
    image: 'https://img.freepik.com/free-photo/flat-lay-natural-cosmetic-products_23-2148890232.jpg?w=996&t=st=1685984407~exp=1685985007~hmac=974bcbb4eeb255b61dfb9c26aa1c1aa06683986259db23cc700d424c83c1e24a',
    title: 'Organic Beauty Essentials',
    description: 'Experience the power of nature with our organic beauty essentials collection.',
    price: 29.99,
    category: {
      name: 'Health care',
    }
  },
  {
    id: 8,
    image: 'https://supplynodes.com/static/media/product2.cd687939bee1cb6c1a9e.png',
    title: 'Fiskars Long-Handled Grass Shears with Swivel Head',
    description: "The innovative design of our Fiskars shears makes trimming grasses easier than ever. It's 36in length eliminates kneeling and bending for fewer post-gardening aches and pains. Position blades vertical for edging, or horizontal for getting into those hard to mow areas.",
    price: 40.64,
    category: {
      name: 'Pliers',
    }
  }
];