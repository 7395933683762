import React, { useState } from "react";

const Input = ({label, type, placeholder, onInputChange }) => {
  const [value, setValue] = useState("");

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
    onInputChange(inputValue);
  };

  return (
    <div className="input_field">
      <label htmlFor={label}>{label}</label>
      <input
        id={label}
        type={type}
        value={value}
        onChange={handleInput}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
