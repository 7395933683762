import React from 'react'
import Main from './components/common/Main.js'
import './components/style/style.min.css'
import './components/style/responsive.css'
const App = () => {
  return (
    <>
      <Main/>
    </>
  )
}

export default App;